<template>
	<div class="home_about">
		<div class="home_about_title">
			<span class="home_about_title_en">ABOUT US</span>
			<p class="home_about_title_cn">关于我们</p>
		</div>

		<div class="home_about_t">
			<div class="home_about_t_r">
				<van-image class="home_about_img" fit="cover" :src="vData.about_img1" />
				<van-image class="home_about_img" fit="cover" :src="vData.about_img2" />
				<van-image class="home_about_img" fit="cover" :src="vData.about_img3" />
				<van-image class="home_about_img" fit="cover" :src="vData.about_img4" />
			</div>

			<div class="home_about_t_l">
				<div class="home_about_t_title1">{{ $title.cn }}</div>
				<div class="home_about_t_title2">{{ $title.en }}</div>

				<div class="home_about_t_con">{{ vData.about_text }}</div>
			</div>
		</div>

		<div class="home_about_b">
			<div class="home_about_b_l">
				<p class="home_about_b_title1">联系我们</p>
				<p class="home_about_b_title2">{{ $title.cn }}</p>

				<div class="home_about_b_con">
					<div class="home_about_b_item">
						<span class="home_about_b_item_label">联系人：</span>
						<span class="home_about_b_item_val">{{ vData.link_name }}</span>
					</div>

					<div class="home_about_b_item">
						<span class="home_about_b_item_label">手机：</span>
						<span class="home_about_b_item_val">{{ vData.link_phone }}</span>
					</div>

					<div class="home_about_b_item"style="width: 100%;">
						<span class="home_about_b_item_label">邮箱：</span>
						<span class="home_about_b_item_val">{{ vData.link_email }}</span>
					</div>

					<div class="home_about_b_item" style="width: 100%;">
						<span class="home_about_b_item_label">地址：</span>
						<span class="home_about_b_item_val">{{ vData.link_address }}</span>
					</div>
				</div>
			</div>

			<div class="home_about_b_r">
				<div class="home_about_b_r_item">
					<van-image class="home_about_b_r_item_img" fit="cover" :src="vData.gzh_qr" />
					<span class="home_about_b_r_item_name">公众号</span>
				</div>

				<div class="home_about_b_r_item">
					<van-image class="home_about_b_r_item_img" fit="cover" :src="vData.xcx_qr" />
					<span class="home_about_b_r_item_name">小程序</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "index",
		data() {
			return {
				vData: {}
			}
		},
		created(){
			this.init();
		},
		methods: {
			async init() {
				this.$loading();
				const res = await this.$api.home();
				this.$loading().clear();
				const data = res.data ? res.data : {};

				this.vData = data.config;
			}
		}
	}
</script>

<style lang="less" scoped>
	@import '~@/assets/fonts/fonts.css';

	.home_about {
		max-width: 102rem;
		margin: 0 auto;
		padding: 8rem 1rem 10rem;
	}

	.home_about_title {
		line-height: 1;
	}

	.home_about_title_en {
		font-size: 1.6rem;
		color: @blackColor;
	}

	.home_about_title_cn {
		font-size: 5.4rem;
		color: @greenColor;
		margin-top: 0.5rem;
	}

	.home_about_t {
		width: 100%;
		display: block;
		padding-bottom: 6.5rem;
		border-bottom: 3px dashed #d5f0d1;
		margin-top: 6rem;
		flex-direction: row-reverse;
	}

	.home_about_t_l {
		width: 100%;
		/*padding-right: 3.5rem;*/
		display: block;
		flex-direction: column;
	}

	.home_about_t_title1 {
		font-size: 3.2rem;
		color: @greenColor;
	}

	.home_about_t_title2 {
		font-size: 1rem;
		color: @blackColor;
	}

	.home_about_t_con {
		font-size: 1.6rem;
		color: @blackColor;
		margin-top: 3rem;
		line-height: 3.5rem;
		font-family: 'shangshushui';
		text-align: justify;
		white-space: pre-wrap;
	}

	.home_about_t_more {
		width: 22.2rem;
		font-size: 2rem;
		color: #ffffff;
		line-height: 3.4rem;
		background-color: @greenDarkColor;
		text-align: center;
		margin-top: 5.5rem;
		cursor: pointer;
	}

	.home_about_t_r {
		float: right;
		flex-shrink: 0;
		width: 34rem;
		height: 34rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: flex-start;
		padding-top: 9rem;
		box-sizing: content-box;
		margin: 0 0 3.5rem 3.5rem;
	}

	.home_about_img {
		flex-shrink: 0;
		width: 49%;
		height: 49%;
		margin-top: 2%;
	}

	.home_about_img:nth-child(1),
	.home_about_img:nth-child(2) {
		margin-top: 0;
	}

	.home_about_b {
		margin-top: 6.5rem;
		display: flex;
	}

	.home_about_b_l {
		flex-shrink: 0;
		width: 60rem;
	}

	.home_about_b_title1 {
		font-size: 2rem;
		color: @blackColor;
	}

	.home_about_b_title2 {
		font-size: 3.2rem;
		color: @greenLightColor;
		margin-top: 1.2rem;
	}

	.home_about_b_con {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		margin-top: 3rem;
	}

	.home_about_b_item {
		flex-shrink: 0;
		width: 50%;
		padding-right: 2rem;
		display: flex;
		align-items: center;
		font-size: 1.6rem;
		margin-bottom: 1.5rem;
		line-height: 1;
	}

	.home_about_b_item_label {
		flex-shrink: 0;
		color: @greenLightColor;
	}

	.home_about_b_item_val {
		width: 100%;
		color: @blackColor;
	}

	.home_about_b_r {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.home_about_b_r_item {
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-family: 'shangshushui';
	}

	.home_about_b_r_item:not(:last-of-type) {
		margin-right: 4.5rem;
	}

	.home_about_b_r_item_img {
		width: 9.6rem;
		height: 9.6rem;
	}

	.home_about_b_r_item_name {
		font-size: 1.6rem;
		color: @blackColor;
		margin-top: 0.5rem;
	}








	@media screen and (max-width: 800px){
		.home_about {
			width: 32.5rem;
			padding: 3.5rem 1rem 4rem;
		}

		.home_about_title {
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
		}

		.home_about_title_cn {
			font-size: 1.9rem;
		}

		.home_about_title_en {
			font-size: 0.6rem;
			margin-top: 0.2rem;
			color: @greenColor;
		}

		.home_about_t {
			display: block;
			margin-top: 1.4rem;
			padding-bottom: 2rem;
		}

		.home_about_t_l {
			padding-right: 1.1rem;
			display: block;
			min-height: 14rem;
		}

		.home_about_t_title1 {
			font-size: 1.3rem;
		}

		.home_about_t_title2 {
			display: none;
		}

		.home_about_t_con {
			margin-top: 0.9rem;
			font-size: 1rem;
			line-height: 1.8;
		}

		.home_about_t_r {
			float: right;
			width: 10.9rem;
			height: 10.9rem;
			padding-top: 2.8rem;
			margin-left: 1rem;
		}

		.home_about_b {
			margin-top: 2rem;
		}

		.home_about_b_l {
			width: 20rem;
		}

		.home_about_b_title1 {
			font-size: 1rem;
		}

		.home_about_b_title2 {
			margin-top: 0.4rem;
			font-size: 1.2rem;
		}

		.home_about_b_con {
			margin-top: 1.2rem;
		}

		.home_about_b_item {
			font-size: 0.9rem;
			margin-bottom: 0.6rem;
		}

		.home_about_b_r_item:not(:last-of-type) {
			margin-right: 0.5rem;
		}

		.home_about_b_r_item_img {
			width: 3.8rem;
			height: 3.8rem;
		}

		.home_about_b_r_item_name {
			font-size: 0.9rem;
		}
	}
</style>